import React, { useReducer } from 'react';
import { login } from '../../utils/utils';

import './login.scss';

function loginReducer(state: any, action: any) {
    switch (action.type) {
    case 'login': {
        return {
            ...state,
            isLoading: true,
            error: '',
        };
    }
    case 'success': {
        return {
            ...state,
            isLoggedIn: true,
        };
    }
    case 'error': {
        return {
            ...state,
            error: 'Incorrect username or password!',
            isLoading: false,
            username: '',
            password: '',
        };
    }
    case 'logout': {
        return {
            ...state,
            isLoggedIn: false,
            username: '',
            password: '',
            isLoading: false,
        };
    }
    case 'field': {
        return {
            ...state,
            [ action.field ]: action.value,
        };
    }
    default:
        break;
    }
    return state;
}
const initialState = {
    username: '',
    password: '',
    isLoading: false,
    error: '',
    isLoggedIn: false,
};


export default (): JSX.Element => {
    const[ state, dispatch ] = useReducer(loginReducer, initialState);

    const { username, password, isLoading, error, isLoggedIn } = state;

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        dispatch({ type: 'login' });
        try {
            await login({ username, password });
            dispatch({ type: 'success' });
        } catch (error) {
            dispatch({ type: 'error' });
        }
    };

    return (
        <>
            { isLoggedIn ? (
                <div className="loggedIn">
                    <h1>Hello {username}!</h1>
                    <button onClick={() => dispatch({ type: 'logout' })} className="btn btn-primary btn-grad">Log Out</button>
                </div>
            ) :
                <div className="loginForm__container">
                    <h1 className="loginForm__title">Please Login!</h1>
                    <form name="contact" onSubmit={onSubmit} >
                        {error && <p>{error}</p>}
                        <input
                            type="text"
                            value={username}
                            placeholder="Enter your username"
                            className="form-control"
                            onChange={ e => dispatch({ type: 'field', field: 'username', value: e.currentTarget.value })}
                        />
                        <input
                            type="password"
                            value={password}
                            placeholder="Enter your password"
                            className="form-control"
                            onChange={ e => dispatch({ type: 'field', field: 'password', value: e.currentTarget.value })}/>
                        <button type="submit" className="btn btn-primary btn-grad" disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Log in'}
                        </button>
                    </form>
                </div>
            }
        </>
    );
};
