import React from 'react';
import Login from '../components/login/Login';
import Layout from '../components/layout/Layout';

export default (): JSX.Element => {
    return (
        <Layout>
            <Login />
        </Layout>
    );
};
